<script lang="ts">
	export let functionToRun: (event: MouseEvent) => void = () => {};
	
    export let style: 'link' | 'default' = 'link';
</script>

<button
	on:click={functionToRun}
	class="{style}"
	{...$$restProps}
>
	<slot />
</button>

<style>
	.link {
		padding: 0.25rem;
		border-radius: 0.25rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		font-weight: 600;
		background-color: transparent;
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 300ms;
		transition-duration: 150ms;

		color: rgb(251 146 60);

		@media (min-width: 1280px) {
			font-size: 1rem;
			line-height: 1.5rem;
		}

		&:hover {
			text-decoration: none;
			background-color: rgb(234 88 12 / 0.3);
		}
	}
</style>
