<script lang="ts">
	import { PUBLIC_AUTH_URL, PUBLIC_BASE_URL } from '$env/static/public';
	import { DeprecatedFetch_useapiFetch } from '$lib/utils/fetch';
	import { user } from '$lib/stores/user';
	import { closeModal, openModal } from 'svelte-modals';

	import Text from '$lib/components/input/text.svelte';
	import Modal from '$lib/components/Modal.svelte';

	import { page } from '$app/stores';

	// import { Loader2, Check } from 'lucide-svelte';
	import LoginButton from './LoginButton.svelte';
	import Icons from '../Icons.svelte';
	import { goto } from '$app/navigation';
	import Card from './Card.svelte';
	// import Passwordless from './Passwordless.svelte';
	import PasswordReset from './PasswordReset.svelte';
	import Register from './Register.svelte';
	import Button from '../ui/Button.svelte';
	type State = 'default' | 'success' | 'loading';

	let errorMessage: string;
	let pass_state: State = 'default';
	let discord_state: State = 'default';
	let steam_state: State = 'default';

	let login_username: string;
	let login_password: string;

	let shadow: boolean | undefined = undefined;

	export let redirect: string = '';

	// async function openStartPasswordless() {
	// 	if ($page.url.pathname.includes('login')) {
	// 		goto('/startpasswordless');
	// 	} else {
	// 		closeModal();
	// 		openModal(Modal, {
	// 			component: Passwordless,
	// 			props: null
	// 		});
	// 	}
	// }

	async function openStartPasswordReset() {
		if ($page.url.pathname.includes('login')) {
			goto('/startpasswordreset');
		} else {
			closeModal();
			openModal(Modal, {
				component: PasswordReset,
				props: null
			});
		}
	}

	async function openRegister() {
		if ($page.url.pathname.includes('login')) {
			goto('/register');
		} else {
			closeModal();
			openModal(Modal, {
				component: Register,
				props: null
			});
		}
	}

	async function handlePassLogin() {
		pass_state = 'loading';

		await DeprecatedFetch_useapiFetch({
			endpoint: '/auth/login',
			method: 'POST',
			body: { username: login_username, password: login_password }
		})
			.then((data) => {
				user.set(JSON.parse(JSON.stringify(data.data)));
				errorMessage = '';
				shadow = true;
				pass_state = 'success';
				if (redirect != '') {
					goto(redirect);
				}
				setTimeout(() => {
					closeModal();
				}, 500);
			})
			.catch((err) => {
				console.log(err);
				errorMessage = 'Något gick fel, försök igen senare.';
				pass_state = 'default';
			});
	}

	async function handleSteamLogin() {
		steam_state = 'loading';

		let steam_window = window.open(
			PUBLIC_AUTH_URL + '/auth/provider/steam/login',
			'_blank',
			'width=500,height=800,popup=1'
		);

		// Listen for messages from the popup
		window.addEventListener('message', (event) => {
			if (event.origin !== PUBLIC_BASE_URL) return;

			// Check the type of the message
			if (event.data.type && event.data.type === 'steam') {
				const data = JSON.parse(event.data.data);
				console.log(data.data);
				steam_window?.close();
				if (data.status === '200 OK') {
					user.set(JSON.parse(JSON.stringify(data.data)));
					errorMessage = '';
					steam_state = 'success';
					clearInterval(checkInterval);
					if (redirect != '') {
						goto(redirect);
					}
					setTimeout(() => {
						closeModal();
					}, 500);
				} else {
					errorMessage = data.message;
					steam_state = 'default';
				}
				console.log(data.message);
			}
		});
		var checkInterval = setInterval(function () {
			if (steam_window?.closed) {
				clearInterval(checkInterval);
				steam_state = 'default';
				console.log("Steam window closed, didn't get a response");
			}
		}, 100);
	}
</script>

<Card>
	<div>
		<h1 class="text-3xl font-semibold text-left text-white">Logga in</h1>
		<span class="flex items-center py-1 space-x-2">
			<h4 class="text-sm xl:text-base text-zinc-400">Inte medlem?</h4>
			<Button functionToRun={openRegister}>Skapa ett konto</Button>
		</span>
	</div>

	<div class="flex flex-col items-center justify-center space-y-5">
		{#if errorMessage}
			<p
				class="flex justify-center w-full px-4 py-2 text-center transition-all duration-150 border border-red-500 rounded text-zinc-100 bg-red-500/30"
			>
				{errorMessage}
			</p>
		{/if}
		<form class="flex flex-col w-full" on:submit|preventDefault={handlePassLogin}>
			<div class="space-y-3">
				<Text
					label="Användarnamn"
					label_for="user"
					bind:value={login_username}
					required={true}
					focus={true}
				/>
				<div class="flex flex-col w-full space-y-1">
					<span class="flex items-center justify-between w-full">
						<label class="ml-1 text-sm xl:text-base text-zinc-200" for="password">Lösenord</label>
						<button
							type="button"
							class="ml-1 text-sm xl:text-base text-zinc-400"
							on:click={openStartPasswordReset}>Glömt lösenordet?</button
						>
					</span>
					<Text bind:value={login_password} required={true} type="password" />
				</div>
			</div>
			<div class="mt-6 space-y-2">
				<LoginButton state={pass_state} type="submit">
					<div class="relative flex items-center justify-center w-full px-4">Logga in</div>
				</LoginButton>
				<div class="flex items-center justify-between mb-1">
					<span class="w-full border-b border-zinc-700" />

					<p class="mx-4 font-sans text-sm font-thin text-center text-zinc-300 whitespace-nowrap">
						Eller med
					</p>

					<span class="w-full border-b border-zinc-700" />
				</div>

				<div class="flex flex-row space-x-3">
					<!-- Steam -->
					<LoginButton state={steam_state} functionToRun={handleSteamLogin} style={'steam'}>
						<div class="flex items-center justify-center w-full h-full space-x-2 text-white">
							<Icons name="steam" />
							<p class="text-lg font-light">Steam</p>
						</div>
					</LoginButton>

					<!-- Discord -->
					<LoginButton
						disabled
						state={discord_state}
						functionToRun={handlePassLogin}
						style={'discord'}
					>
						<div class="flex items-center justify-center w-full h-full space-x-2 text-white">
							<Icons name="discord" />
							<p class="text-lg font-light">Discord</p>
						</div>
					</LoginButton>
				</div>
			</div>
		</form>
	</div>
</Card>
