<script lang="ts">
	import { DeprecatedFetch_useapiFetch } from '$lib/utils/fetch';
	import { Loader2 } from 'lucide-svelte';

	import { cn } from '$lib/utils/utils';
	import { browser } from '$app/environment';

	import Text from '$lib/components/input/text.svelte';
	import { goto } from '$app/navigation';
	import LoginButton from './LoginButton.svelte';
	import Checkbox from '$lib/components/ui/Checkbox.svelte';
	import Card from './Card.svelte';
	import Button from '../ui/Button.svelte';
	import { page } from '$app/stores';
	import { closeModal, openModal } from 'svelte-modals';
	import Modal from '$lib/components/Modal.svelte';
	import Login from './Login.svelte';
	import { user } from '$lib/stores/user';

	let username: string;
	let password: string;
	let email: string;
	let social: string;
	let number: string;

	let login_password: string;
	let login_username: string;

	let user_agreement: boolean;
	let privacy_agreement: boolean;
	let playpark_stadgar: boolean;

	let modalStyle: string;
	let isLoading: boolean;
	let defaultStyle = 'p-2 border rounded-md border-zinc-800 w-full flex justify-center';

	let registerMessage: string;

	async function openLogin() {
		if ($page.url.pathname.includes('register')) {
			goto('/login');
		} else {
		closeModal();
		openModal(Modal, {
			component: Login,
			props: null
		});
	}
	}

	const handleRegister = async () => {
		registerMessage = '';
		isLoading = true;

		await DeprecatedFetch_useapiFetch({
			endpoint: '/auth/register',
			method: 'POST',
			body: {
				username: username,
				password: password,
				email: email,
				personnummer: social,
				telefonnummer: number,
				user_agreement,
				privacy_agreement,
				playpark_stadgar
			}
		})
			.then((data) => {
				registerMessage = 'Registrerad!';
				modalStyle = 'border-green-500 bg-green-500/30';
				isLoading = false;
				login_username = username;
				login_password = password;
				setTimeout(() => {
					loginAfterRegister();
				}, 4000);
			})
			.catch((err) => {
				registerMessage = 'Något gick fel med registreringen, försök igen senare';
				modalStyle = 'border-red-500 bg-red-500/30';
				isLoading = false;
			});

		return;
	};

	const loginAfterRegister = async () => {
		isLoading = true;
		modalStyle = 'border-orange-500 bg-orange-500/30';
		registerMessage = 'Loggar in...';
		await DeprecatedFetch_useapiFetch({
			endpoint: '/auth/login',
			method: 'POST',
			body: { username: login_username, password: login_password }
		})
			.then((data) => {
				isLoading = false;

				modalStyle = 'border-green-500 bg-green-500/30';
				user.set(JSON.parse(JSON.stringify(data.data)));
				setTimeout(() => {
					goto('/');
				}, 1000);
			})
			.catch((err) => {
				console.log(err);
				isLoading = false;
				registerMessage = 'Något gick fel med att logga in, försök igen senare';
				modalStyle = 'border-red-500 bg-red-500/30';
			});
	};
</script>

<Card>
	<div>
		<h1 class="text-3xl font-semibold text-left text-white">Bli medlem</h1>
		<span class="flex items-center py-1 space-x-2">
			<h4 class="text-sm xl:text-base text-zinc-400">Redan medlem?</h4>
			<Button functionToRun={openLogin}>
				Logga in
			</Button>
		</span>
	</div>

	<div class="flex flex-col items-center justify-center space-y-5">
		{#if registerMessage}
			<p class={cn(defaultStyle, modalStyle)}>{registerMessage}</p>
		{/if}
		<form class="flex flex-col w-full space-y-3" on:submit|preventDefault={handleRegister}>
			<Text
				bind:value={username}
				label={'Användarnamn'}
				label_for={'username'}
				required={true}
				focus={true}
			/>
			<Text bind:value={email} label={'E-post'} label_for={'email'} required={true} />
			<Text
				bind:value={password}
				label={'Lösenord'}
				label_for={'password'}
				required={true}
				type="password"
			/>
			<Text bind:value={social} label={'Personnummer'} label_for={'social'} required={true} />
			<Text bind:value={number} label={'Telefonnummer'} label_for={'number'} required={true} />
			<!-- Accept all agreements -->
			<div class="flex flex-col gap-y-3">
				<span>
					<Checkbox required id="user_agreement" name="user_agreement" bind:checked={user_agreement}>
						<label for="user_agreement" class="text-sm text-white">
							Jag godkänner <a href="/terms" class="text-blue-400 underline">användaravtalet</a>
						</label>
					</Checkbox>

					
				</span>
				<!-- <span>
					<input
						type="checkbox"
						id="privacy_agreement"
						name="privacy_agreement"
						bind:checked={privacy_agreement}
						required
					/>
					<label for="privacy_agreement" class="text-sm text-white">
						Jag godkänner <a href="/terms" class="text-blue-400 underline">integritets policyn</a>
					</label>
				</span> -->
				<span>
					<Checkbox required id="playpark_stadgar" name="playpark_stadgar" bind:checked={playpark_stadgar}>
						<label for="playpark_stadgar" class="text-sm text-white">
							Jag godkänner <a href="/stadgar" class="text-blue-400 underline">Playparks stadgar</a>
						</label>
					</Checkbox>
					
				</span>
			</div>
			<div class="h-full">
				<LoginButton state={isLoading ? 'loading' : 'default'} type="submit">
					<div class="relative flex items-center justify-center w-full px-4">Registrera</div>
				</LoginButton>
			</div>
		</form>
	</div>
</Card>

<style>
</style>
